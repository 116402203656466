/* eslint-disable */
<template>
  <v-container
    id="carbon-calculator"
    fluid
    tag="section"
  >

  <div v-if="user_information_entered">
  <h1 id="sticky-top-header" class="col-12 page-title mb-3">
    <v-row align="center">
      <div id="top-header-flex" class="">
        <div class="p-2">
              <span class="mt-5">
                <v-img class="logo-upe" id="logo-upe" src="/UPE Logo.png" width="140px"/>
              </span>
        </div>
        <div class="p-2">
              <div id="page-title" class="titre-page">
                Mesure de l'empreinte carbone d'une campagne en communication extérieure
              </div>
        </div>
        <div class="p-2">
          <div class="adoohcc-div">
              <span id="adoohcc-logo">
                adOOHcc
              </span>
          <span class="adoohcc-legende" >
            advertising Out Of Home calculateur carbone
              </span>
          </div>
        </div>
      </div>
    </v-row>
  </h1>
  <div id="dashboard-content" class="row">
  <div id="inputs-section" class="col-12 col-md-6 row">
  <div id="inputs-top-section" class="col-12 row">
  <div id="inputs-header" class="offset-1 col-10">
  <v-row align="center" class="mt-2">
  <div id="dialogs-section">

  <v-dialog
    v-model="dialog_approach"
    width="1000"
  >
  <template v-slot:activator="{ on, attrs }">
                    <span
                      id="approach-button"
    @click="dialog_approach = true"
    >
    <span class="float-right">
                        Notre démarche carbone
                        <v-icon class="mr-1" x-large
                        >
                          mdi-recycle
                        </v-icon>
                      </span>
  </span>
</template>

<v-card>
<v-card-title class="text-h5 grey lighten-2">
  <h2 class="mb-4 font-weight-bold">Notre démarche carbone</h2>
</v-card-title>

<v-card-text id="approach-card">
  Dans la continuité des engagements pris en mars 2021 en faveur
  de la transition écologique (<a href="http://www.upe.fr/fichiers/les-engagements-climat-de-l-UPE_mars2021.pdf" target="#">lire les engagements de l'UPE</a>),
  les membres de l’Union de la Publicité Extérieure ont
  développé un outil permettant de mesurer l’empreinte carbone
  des campagnes de communication extérieure, avec l’appui du
  cabinet Axionable.
  <br/>
  <br/>
  A travers cet outil, l’UPE et ses membres souhaitent pouvoir répondre aux attentes des agences et
  annonceurs, et plus largement des citoyens et consommateurs, en faveur d’une totale transparence sur
  l’impact carbone de la communication extérieure.
  <br/>
  <br/>
  Nommé adOOHc (advertising Out Of Home calculateur carbone), ce calculateur permet d’évaluer les émissions
  de CO<sub>2</sub> générées par les campagnes print et digitales, sur l’ensemble de leur cycle de vie et d’obtenir la
  répartition de ces émissions pour chacune des étapes (matière première et fabrication, transport,
  installation, exploitation, fin de vie). Le calculateur permet en outre de distinguer l’empreinte carbone
  de la seule diffusion des campagnes.
  <br/>
  <br/>
  Conçu pour être simple d’utilisation, il intègre les principaux formats papier, ainsi que les formats indoor
  et outdoor en numérique, et permet de mesurer rapidement l’impact carbone d’un plan média en
  communication extérieure.
  <br/>
  <br/>
  <span
    id="more-information-button"
  @click="dialog_approach = false; dialog_how_to = true"
  >
  <span class="float-right">
                          Obtenir plus de précisions sur la méthodologie
                          <v-icon class="mr-1" x-large
                          >
                            mdi-information-outline
                          </v-icon>
                        </span>
</span>
</v-card-text>

<v-divider></v-divider>

<v-card-actions>
<v-spacer></v-spacer>
<v-btn
  color="primary"
  text
@click="dialog_approach = false"
>
Fermer
</v-btn>
  </v-card-actions>
  </v-card>
  </v-dialog>

<v-dialog
v-model="dialog_how_to"
width="1000"
>
<template v-slot:activator="{ on, attrs }">
                    <span
                      id="more-information-button"
  @click="dialog_how_to = true"
  >
  <span class="float-right">
                        Méthodologie détaillée
                        <v-icon class="mr-1" x-large
                        >
                          mdi-information-outline
                        </v-icon>
                      </span>
</span>
</template>

<v-card>
<v-card-title class="text-h5 grey lighten-2">
  <h2 class="mb-4 font-weight-bold">Méthodologie détaillée</h2>
</v-card-title>

<v-card-text id="methodology-card">
  <h3>1. Fonctionnement de l’outil</h3>
  Le calculateur a été créé à partir des ACV des 4 principaux acteurs français de la publicité extérieure, qui ont été élaborées conformément à la norme ISO 14040. A partir de ces ACV, il a été possible de déduire pour chaque acteur et pour plusieurs formats un facteur d’émission :<br/>
  <ul>
    <li>Par jour par face de panneaux pour l’affichage print</li>
    <li>Par seconde de diffusion par face de panneaux pour l’affichage digital</li>
  </ul>
  Chaque facteur a été décomposé selon 5 postes d’émissions détaillés plus bas, conformément à la répartition de l’analyse du cycle de vie du panneau.<br/><br/>
  Les facteurs de chaque membre ont ensuite pu être pondérés par le patrimoine (en nombre de faces) des différents acteurs, pour mieux représenter la réalité du secteur via l’élaboration d’indicateurs d’émissions moyens.<br/><br/>
  Les émissions de CO<sub>2</sub> exprimées dans le calculateur correspondent à du CO<sub>2</sub> équivalent.
  <br/>
  <br/>
  <h3>2. Périmètre du calculateur</h3>
  Le périmètre du calculateur couvre les 5 postes d’émissions liés au cycle de vie des mobiliers :<br/>
  <ul>
    <li>Matières premières et fabrication : correspond aux émissions liées à la fabrication du panneau</li>
    <li>Transport : correspond aux émissions liées au transport du mobilier avant son installation</li>
    <li>Installation : correspond à l’installation du panneau</li>
    <li>Exploitation : correspond aux phases d’utilisation du panneau, de maintenance et intègre le cycle de vie complet des affiches (pour la partie print)</li>
    <li>Fin de vie : correspond à la désinstallation du panneau, à son transport vers son lieu de fin de vie et <u>à son éventuel recyclage (et revalorisation en fin de vie)</u>. La présence éventuelle de postes d’émissions négatifs pour la fin de vie est due au
      recyclage des panneaux.</li>
  </ul>
  Le calculateur n’inclut pas les émissions liées à la production des contenus affichés sur les panneaux (print et digital), ni les émissions liées aux frais de siège des différents acteurs de la publicité extérieure.<br/><br/>
  La diffusion correspond au poste « utilisation » du panneau, à savoir : utilisation du panneau, maintenance et cycle de vie complet des affiches.<br/><br/>
  <h3>3. Formats considérés</h3>
  Les formats considérés sont les suivants :<br/>
  <br/>
  <u>Affichage print (14 formats)</u><br/>
  <ul>
    <li>0,5m2 non éclairé</li>
    <li>1m2 éclairé/non éclairé</li>
    <li>1,5m2 colle (rampes métro)</li>
    <li>2m2 fixe/déroulant pondéré</li>
    <li>3m2 colle</li>
    <li>4m2 colle</li>
    <li>6m2 colle</li>
    <li>8m2 fixe/déroulant pondéré</li>
    <li>12m2</li>
    <li>Avant bus</li>
    <li>Arrière classique bus</li>
    <li>Arrière bus autres formats</li>
    <li>Flanc droit et flanc gauche bus</li>
    <li>Colonne fixe</li>
  </ul>
  <u>Affichage digital (2 formats)</u><br/>
  <ul>
    <li>Tous formats outdoor pondéré DOOH</li>
    <li>Tous formats indoor pondéré DOOH</li>
  </ul>
  <br/>
  <h3>4. Partis pris méthodologique</h3>
  L’intégralité des acteurs se sont accordés sur les éléments suivants :<br/>
  <ul>
    <li>Le patrimoine est mis à jour par l’intégralité des acteurs de manière annuelle. Le patrimoine pris en compte pour cette version du calculateur est le patrimoine au 31/12/2019.</li>
    <li>Afin de garder une cohérence au sein des acteurs et entre les acteurs, les facteurs d’émissions ont été pondérés selon le nombre de faces des différents panneaux.</li>
    <li>Un seul facteur d’émission a été considéré pour les formats « fixe/déroulant pondéré » et digitaux (« indoor pondéré » et « outdoor pondéré ») afin de faciliter l’utilisation de l’outil. Ce facteur d’émission est issu d’une
      pondération des différents sous-formats le composant, à partir du nombre de faces disponibles au sein des différents acteurs.</li>
    <li>Compte tenu du calcul à l’échelle d’une campagne, nous n’avons pas pris en compte de notion de taux de remplissage pour les formats digitaux.</li>
  </ul>
  La multiplicité et la diversité des formats existants en publicité extérieure ont nécessité d’établir des règles de moyennisation permettant de conserver la simplicité d’usage de l’outil sur la base des projections de plans média des annonceurs.<br/>
  <br/>
  <h3>5. Limites actuelles et évolutions programmées du calculateur</h3>
    Certains formats spécifiques font apparaître des postes d'émissions de CO<sub>2</sub> nulles.
    Par exemple, un format apposé contre un mur dans le métro aura un poste de fabrication et d'installation nul dans la mesure où l'affiche est collée à même le mur et n’a pas nécessité l’installation préalable d’un mobilier.
    Il convient également de rappeler que les données des ACV utilisées pour le calculateur d’empreinte carbone seront amenées à être précisées et complétées au cours du temps, et en fonction des éventuelles évolutions patrimoniales au sein des différents réseaux concernés.
  </ul>
</v-card-text>

<v-divider></v-divider>

<v-card-actions>
  <v-spacer></v-spacer>
  <v-btn
    color="primary"
    text
  @click="dialog_how_to = false"
  >
  Fermer
</v-btn>
</v-card-actions>
  </v-card>
  </v-dialog>
  </div>
<span class="col-12 m-auto">
                Ce calculateur permet de prévoir les émissions en CO<sub>2</sub> liées aux campagnes publicitaires. Cliquez ci-dessous pour commencer à entrer vos données de campagnes.
              </span>
<v-dialog
v-model="dialogOOHDOOH"
max-width="500px"
>
<template v-slot:activator="{ on, attrs }">
  <div class="col-12">
    <v-btn
      id="add-new-format-button"
      color="#b02722"
      class="m-auto d-block"
      v-bind="attrs"
      v-on="on"
      middle
    >
                      <span id="add-new-format-button-text">
                        Ajouter un format à ma campagne
                      </span>
    </v-btn>
  </div>
</template>
<v-card>
  <div id="tabs-results" class="col-12">
    <v-toolbar
      height="40"
      flat
      margin="auto"
    >
      <template>
        <v-tabs
          v-model="tabs_new_format_type"
          fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            href="#ooh"
            class="primary--text"
            v-if="!currentlyEditingFormat || tabs_new_format_type == 'ooh'"
          >
            <span>Print</span>
          </v-tab>

          <v-tab
            href="#dooh"
            class="primary--text"
            v-if="!currentlyEditingFormat || tabs_new_format_type == 'dooh'"
          >
            <span>Digital</span>
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
  </div>
  <div id="new_item_card" class="col-12">
    <v-tabs-items
      v-model="tabs_new_format_type"
    >
      <v-tab-item
        :key="1"
        :value="'ooh'"
      >
        <v-app
          class="mt-4"
          id="cards-results"
        >
          <v-stepper
            v-model="current_new_item_step"
            vertical
            non-linear
          >
            <v-stepper-step
              :complete="editedItemOOH.panel_format.length > 0"
              step="1"
            >
              Format
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-col
                cols="12"
                class="pt-0 pb-0"
              >
                <v-select
                  :items="panel_formats"
                  label="Format des affiches"
                  v-model="editedItemOOH.panel_format"
                  dense
                  solo
                ></v-select>
              </v-col>
            </v-stepper-content>

            <v-stepper-step
              :complete="editedItemOOH.panel_num.length > 0"
              step="2"
            >
              Nombre de faces
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-col
                cols="12"
                md="6"
                class="pt-0 pb-0"
              >
                <v-text-field
                  v-model="editedItemOOH.panel_num"
                  solo
                  label="0"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-stepper-content>

            <v-stepper-step
              :complete="editedItemOOH.panel_duration.length > 0"
              step="3"
            >
              <span>Durée de la campagne <b>en jours</b></span>
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-col
                cols="12"
                md="6"
                class="pt-0 pb-0"
              >
                <v-text-field
                  v-model="editedItemOOH.panel_duration"
                  solo
                  label="0"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-stepper-content>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
              @click="closeOOHDOOH"
              >
              Annuler
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
            @click="saveOOH"
            :disabled="!editedItemOOHComplete"
            >
            <span v-if="currentlyEditingFormat">
                                Mettre à jour
                              </span>
            <span v-else>
                                Ajouter
                              </span>
          </v-btn>
        </v-card-actions>
      </v-stepper>
    </v-app>
  </v-tab-item>
  <v-tab-item
    :key="2"
    :value="'dooh'"
  >
    <v-app
      id="cards-results"
    >
      <v-stepper
        v-model="current_new_item_step"
        vertical
        non-linear
      >
        <v-stepper-step
          :complete="editedItemDOOH.screen_format.length > 0"
          step="1"
        >
          Format
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-col
            cols="12"
            class="pt-0 pb-0"
          >
            <v-select
              :items="screen_formats"
              label="Type d'écran"
              v-model="editedItemDOOH.screen_format"
              dense
              solo
            ></v-select>
          </v-col>
        </v-stepper-content>

        <v-stepper-step
          :complete="editedItemDOOH.screen_logs.length > 0"
          solo
          label="0"
          step="2"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                                  <span
                                    v-bind="attrs"
                                    v-on="on">
                                    Nombre de logs <v-icon class="ml-1" small
                                  >
                                      mdi-information-outline
                                    </v-icon>
                                  </span>
                </template>
                <span>Un log correspond à l’affichage d’un spot sur un écran publicitaire numérique</span>
              </v-tooltip>
            </template>
          </v-tooltip>
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-col
            cols="12"
            md="6"
            class="pt-0 pb-0"
          >
            <v-text-field
              v-model="editedItemDOOH.screen_logs"
              solo
              label="0"
              type="number"
            ></v-text-field>
          </v-col>
        </v-stepper-content>

        <v-stepper-step
          :complete="editedItemDOOH.screen_duration.length > 0"
          step="3"
        >
          <span>Durée du spot <b>en secondes</b></span>
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-col
            cols="12"
            md="6"
            class="pt-0 pb-0"
          >
            <v-text-field
              v-model="editedItemDOOH.screen_duration"
              solo
              label="0"
              type="number"
            ></v-text-field>
          </v-col>
        </v-stepper-content>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
          @click="closeOOHDOOH"
          >
          Annuler
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
        @click="saveDOOH"
        :disabled="!editedItemDOOHComplete"
        >
        <span v-if="currentlyEditingFormat">
                                Mettre à jour
                              </span>
        <span v-else>
                                Ajouter
                              </span>
      </v-btn>
    </v-card-actions>
  </v-stepper>
</v-app>
</v-tab-item>
  </v-tabs-items>
  </div>
  </v-card>
  </v-dialog>
<v-dialog
v-model="dialogDeleteOOH"
max-width="500px"
>
<v-card>
  <v-card-title class="text-h5">Confirmer la suppression de cet élément?</v-card-title>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn
      color="blue darken-1"
      text
    @click="closeDeleteOOH"
    >
    Annuler
  </v-btn>
  <v-btn
    color="blue darken-1"
    text
  @click="deleteItemConfirmOOH"
  >
  Oui
</v-btn>
<v-spacer></v-spacer>
</v-card-actions>
  </v-card>
  </v-dialog>
<v-dialog
v-model="dialogDeleteDOOH"
max-width="500px"
>
<v-card>
  <v-card-title class="text-h5">Confirmer la suppression de cet élément?</v-card-title>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn
      color="blue darken-1"
      text
    @click="closeDeleteDOOH"
    >
    Annuler
  </v-btn>
  <v-btn
    color="blue darken-1"
    text
  @click="deleteItemConfirmDOOH"
  >
  Oui
</v-btn>
<v-spacer></v-spacer>
</v-card-actions>
  </v-card>
  </v-dialog>
  </v-row>
  </div>
  </div>
<div id="section-separator" class="col-12">
</div>
<h3 class="card-results-header results-title col-12 mt-4 pt-0 pb-0">
Récapitulatif de ma campagne
</h3>
<div class="col-12" id="data-tables">
<v-app
  class="mt-0"
  id="panel-OOH"
>
  <v-data-table
    :headers="ooh_table_headers"
    :items="ooh_items"
    sort-by=""
    class="elevation-1"
    :items-per-page="-1"
  >
    <template v-for="h in ooh_table_headers" v-slot:[`header.${h.value}`]="{ header }">
    <v-tooltip bottom v-if="h.tooltip">
      <template v-slot:activator="{ on }">
                      <span v-on="on">{{h.text}} <b v-if="h.bold">{{h.bold}} </b><v-icon small
                      >
                        mdi-information-outline
                      </v-icon></span>
      </template>
      <span>{{h.tooltip}}</span>
    </v-tooltip>
    <span v-else>
                    {{h.text}} <b v-if="h.bold">{{h.bold}} </b>
                  </span>
  </template>
  <template v-slot:no-data>
    <v-alert :value="true">
      Ajoutez un premier format Print à votre campagne pour le faire apparaître dans cette section
    </v-alert>
  </template>
  <template v-slot:top>
    <div class="col-12 pb-0">
      <h3 class="data-tables-title">
        <v-img
          id="print-image"
          height="28"
          width="10"
          class="d-inline-block"
          src="/icons/svg/print.svg"
        ></v-img>
        <span>
                        Mes formats print (OOH)
                      </span>
      </h3>
    </div>
    <v-divider class="col-12"></v-divider>
  </template>
  <template v-slot:item.actions="{ item }">
    <v-icon
    @click="editItemOOH(item)"
    >
    mdi-pencil
  </v-icon>
  <v-icon
    class="ml-2"
  @click="deleteItemOOH(item)"
  >
  mdi-delete
</v-icon>
</template>
  </v-data-table>
  </v-app>
<v-app
class="mt-7"
id="panel-DOOH"
>
<v-data-table
  :headers="dooh_table_headers"
  :items="dooh_items"
  sort-by=""
  class="elevation-1"
  :items-per-page="-1"
>
  <template v-for="h in dooh_table_headers" v-slot:[`header.${h.value}`]="{ header }">
  <v-tooltip bottom v-if="h.tooltip">
    <template v-slot:activator="{ on }">
                      <span v-on="on">{{h.text}} <b v-if="h.bold">{{h.bold}} </b><v-icon small
                      >
                        mdi-information-outline
                      </v-icon></span>
    </template>
    <span>{{h.tooltip}}</span>
  </v-tooltip>
  <span v-else>
                    {{h.text}} <b v-if="h.bold">{{h.bold}} </b>
                  </span>
</template>
<template v-slot:no-data>
  <v-alert :value="true">
    Ajoutez un premier format Digital à votre campagne pour le faire apparaître dans cette section
  </v-alert>
</template>
<template v-slot:top>
  <div class="col-12 pb-0">
    <h3 class="data-tables-title">
      <v-img
        id="digital-image"
        height="27"
        width="10"
        class="d-inline-block"
        src="/icons/svg/digital.svg"
      ></v-img>
      <span>
                        Mes formats digitaux (DOOH)
                      </span>
    </h3>
  </div>
  <v-divider class="col-12"></v-divider>
</template>
<template v-slot:item.actions="{ item }">
  <v-icon
  @click="editItemDOOH(item)"
  >
  mdi-pencil
</v-icon>
<v-icon
  class="ml-2"
@click="deleteItemDOOH(item)"
>
mdi-delete
</v-icon>
  </template>
  </v-data-table>
  </v-app>
  </div>
  </div>
<div id="results-section" class="col-12 col-md-6 row">
<v-row id="results-header" align="center" class="mt-1">
  <h2 class="results-title col-12">
    <v-img
      id="calculator-image"
      height="60"
      width="60"
      color="red"
      src="/icons/svg/calculator.svg"
    ></v-img>
    Empreinte carbone de ma campagne
  </h2>
</v-row>
<v-row id="summary-results" class="space-around mt-0">
  <v-col
    cols="11"
    lg="5"
    xl="4"
  >
    <base-material-stats-card
      color="info"
      :icon="'/icons/svg/CO2.svg'"
      :sizeCard="50"
      height="75"
      title="Empreinte totale"
      :value="results_loading ? '' : total_emission_display_value"
    />
  </v-col>
  <v-col
    cols="11"
    lg="5"
    xl="4"
  >
    <base-material-stats-card
      color="info"
      :icon="'/icons/svg/diffusion.svg'"
      :sizeCard="50"
      height="75"
      title="Exploitation / Diffusion"
      :value="results_loading ? '' : exploitation_emission_display_value"
    />
  </v-col>
</v-row>
<div id="tabs-results" class="col-12 mt-6">
  <v-toolbar
    height="40"
    flat
    margin="auto"
    background="dddddd"
  >
    <template>
      <v-tabs
        v-model="tabs_results"
        fixed-tabs
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          href="#results-tabs-5-1"
          class="primary--text"
        >
          <span>Détails</span>
        </v-tab>

        <v-tab
          href="#results-tabs-5-2"
          class="primary--text"
        >
          <span>Graphique</span>
        </v-tab>
      </v-tabs>
    </template>
  </v-toolbar>
</div>
<div id="results-details" class="col-12">
  <v-tabs-items
    v-model="tabs_results"
  >
    <v-tab-item
      :key="1"
      :value="'results-tabs-5-' + 1"
    >
      <v-app
        id="cards-results"
      >
        <v-row>
          <h3 class="card-results-header results-title col-12">
            Répartition des émissions par étape de cycle de vie
          </h3>
        </v-row>
        <v-row justify="space-around" id="results-values-section">
          <v-col
            cols="12"
            lg="6"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on">
                                <base-material-stats-card
                                  color="info"
                                  :icon="'/icons/png/factory.png'"
                                  :sizeCard="50"
                                  height="75"
                                  title="Extraction & fabrication"
                                  :value="results_loading ? '' : fabrication_emission_display_value"
                                />
                              </span>
                  </template>
                  <span>Émissions liées à la fabrication du panneau</span>
                </v-tooltip>
              </template>
            </v-tooltip>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on">
                                <base-material-stats-card
                                  color="info"
                                  :icon="'/icons/png/transport_icon.png'"
                                  :sizeCard="50"
                                  height="75"
                                  title="Transport"
                                  :value="results_loading ? '' : transport_emission_display_value"
                                />
                              </span>
                  </template>
                  <span>Émissions liées au transport du mobilier avant son installation</span>
                </v-tooltip>
              </template>
            </v-tooltip>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on">
                                <base-material-stats-card
                                  color="info"
                                  :icon="'/icons/svg/installation.svg'"
                                  :sizeCard="50"
                                  height="75"
                                  title="Installation"
                                  :value="results_loading ? '' : installation_emission_display_value"
                                />
                              </span>
                  </template>
                  <span>Emissions liées à l’installation du panneau</span>
                </v-tooltip>
              </template>
            </v-tooltip>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on">
                                <base-material-stats-card
                                  color="info"
                                  :icon="'/icons/svg/exploitation.svg'"
                                  :sizeCard="50"
                                  height="75"
                                  title="Exploitation / Diffusion"
                                  :value="results_loading ? '' : exploitation_emission_display_value"
                                />
                              </span>
                  </template>
                  <span>Émissions liées à l’utilisation du panneau, sa maintenance et le cycle de vie des affiches (pour la partie print)</span>
                </v-tooltip>
              </template>
            </v-tooltip>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on">
                                <base-material-stats-card
                                  color="info"
                                  :icon="'/icons/svg/fin_de_vie.svg'"
                                  class="mb-3"
                                  :sizeCard="50"
                                  height="75"
                                  title="Fin de vie"
                                  :value="results_loading ? '' : fin_de_vie_emission_display_value"
                                />
                              </span>
                  </template>
                  <span>Émissions liées à la désinstallation du panneau, à son transport vers son lieu de fin de vie et <u>à son éventuel recyclage (et revalorisation en fin de vie)</u>.<br/>La présence éventuelle de postes d’émissions négatifs pour la fin de vie est due au recyclage des panneaux.</span>
                </v-tooltip>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-app>
    </v-tab-item>
    <v-tab-item
      :key="2"
      :value="'results-tabs-5-' + 2"
    >
      <v-app
        class="mt-4"
        id="graph-results"
      >
        <v-row>
          <h3 class="card-results-header results-title col-12">
            Visualisation de la répartition des émissions par étape de cycle de vie
          </h3>
        </v-row>
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
      </v-app>
    </v-tab-item>
  </v-tabs-items>

  <v-col
    cols="12"
    class="mt-4 mb-4"
    id="action-buttons"
  >
    <v-row class="space-around">
      <v-btn
        id="reset-button"
        color="#b02722"
        class="col-12 col-md-5 col-lg-4 col-xl-3 m-auto d-block mb-4"
        middle
      @click="resetData"
      >
      <span id="reset-button-text" class="btn-secondary">
                      Réinitialiser données
                    </span>
    </v-btn>


    <v-btn
      id="reset-button"
      color="#b02722"
      class="col-12 col-md-5 col-lg-4 col-xl-3 m-auto d-block mb-4"
      middle
      v-on:click="testWorksheet"
    >
      <span id="download-button-text" class="btn-secondary">
                      Télécharger résultats
      </span>
    </v-btn>

  <!--
    <xlsx-workbook
      class="col-12 col-md-5 col-lg-4 col-xl-3 m-auto pl-0 pr-0 d-block">
      <xlsx-sheet
        :collection="sheet.data"
        v-for="sheet in sheets"
        :key="sheet.name"
        :sheet-name="sheet.name"
      />
      <xlsx-download :filename="'Emissions carbones de mes campagnes publicitaires - Analyse du ' + getTimestamp() + '.xlsx'">
        <v-btn
          id="download-button"
          color="#b02722"
          middle
        >
                        <span id="download-button-text">
                          Télécharger résultats
                        </span>
        </v-btn>
      </xlsx-download>
    </xlsx-workbook>
    -->

  </v-row>
</v-col>
</div>
  </div>
  </div>
  </div>
<div v-else>
<v-container
  id="user-profile"
  fluid
  tag="section"
>
<v-row justify="center">
<v-col
  cols="12"
  md="8"
>
  <base-material-card id="user-information-card">
  <template v-slot:heading>
    <div class="display-2 font-weight-light">
      Calculateur adOOHcc - Formulaire pré-utilisation
    </div>

    <div class="subtitle-1 font-weight-light">
      Merci de renseigner les informations ci-dessous pour accéder au calculateur
    </div>
  </template>

  <v-form ref="user_information_form" class="mt-7" lazy-validation>
  <v-container class="py-0">
  <v-row>

    <v-col
      cols="12"
      class="pb-0"
    >
      <small class="float-right">
        * champ obligatoire
      </small>
    </v-col>

    <v-col
      cols="12"
      md="6"
      class="pt-0"
    >
      <v-text-field
        v-model="user_information.firstname"
        :rules="requiredTextRules"
        label="Prénom *"
        required
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
      class="pt-0"
    >
      <v-text-field
        v-model="user_information.lastname"
        :rules="requiredTextRules"
        label="Nom *"
        required
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model="user_information.email"
        :rules="emailRules"
        label="Adresse mail *"
        required
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-select
        :items="field_values"
        :rules="selectorRules"
        label="Secteur *"
        v-model="user_information.field"
        required
      ></v-select>
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model="user_information.company"
        :rules="nonRequiredTextRules"
        label="Entreprise"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model="user_information.position"
        :rules="nonRequiredTextRules"
        label="Poste occupé"
      />
    </v-col>

    <v-col
      cols="12"
      class="pt-0 pb-0"
    >
      <v-checkbox
        v-model="cookies_accepted"
        :rules="requiredCheckbox"
        value="red darken-3"
        color="red darken-3"
        label="En cochant cette case, j'accepte l'utilisation des cookies"
        hide-details
        required
      ></v-checkbox>
    </v-col>

    <v-col
      cols="12"
      class="pt-0 pb-0"
    >
      <v-checkbox
        v-model="gdpr_accepted"
        :rules="requiredCheckbox"
        class="mt-0"
        value="red darken-3"
        color="red darken-3"
        label="En cochant cette case, je reconnais avoir pris connaissance des Conditions générales d'utilisation et donne mon accord pour la récolte de mes informations utilisateurs à des fins statistiques"
        hide-details
        required
      ></v-checkbox>

      <v-dialog
        v-model="dialog_gdpr"
        width="1000"
      >
        <template v-slot:activator="{ on, attrs }">
                          <span
                            id="more-information-button"
          @click="dialog_gdpr = true"
          >
          <span class="float-right text-decoration-underline">
                              En savoir plus sur l'utilisation de ce données
                            </span>
        </span>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <h2 class="mb-4 font-weight-bold">Utilisation des données du formulaire</h2>
        </v-card-title>

        <v-card-text id="methodology-card">
          Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par l’UPE aux seules fins de statistiques. La base légale du traitement est fondée sur le consentement de l’utilisateur
          <br/>
          <br/>
          Les données collectées seront communiquées aux seuls destinataires suivants : adhérents de l’UPE
          <br/>
          <br/>
          Les données sont conservées pendant la durée de fonctionnement de l’outil
          <br/>
          <br/>
          Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données. Vous pouvez retirer à tout moment votre consentement au traitement de vos données
          <br/>
          <br/>
          Consultez le site <a href="http://www.cnil.fr" target="_">cnil.fr</a> pour plus d’informations sur vos droits
          <br/>
          <br/>
          Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter le service chargé de l’exercice de ces droits : Laure Sorlot – contact@upe.f
          <br/>
          <br/>
          Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
          @click="dialog_gdpr = false"
          >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-col>

<v-col
  cols="12"
  class="mt-4"
>
<div id="recaptcha-section">
  <div v-if="display_captcha_required_message">
    <div class="form-item-incomplete"><i>Merci de valider le captcha</i></div>
  </div>
  <vue-recaptcha
    ref="recaptcha"
  @verify="applyValidCaptcha()"
  sitekey="6LdaohseAAAAAIeNm5gyhYn-sS5ulVwoBezlAH9C"
  :loadRecaptchaScript="true"
  >
</vue-recaptcha>
</div>
  </v-col>

<v-col
cols="12"
class="text-center"
>
<v-btn
  color="success"
  class="mr-0"
  id="submit-user-information-button"
@click="submitUserInformationForm"
>
Accéder au calculateur
</v-btn>
  </v-col>
  </v-row>
  </v-container>
  </v-form>
  </base-material-card>
  </v-col>
  </v-row>
  </v-container>
  </div>
  </v-container>
  </template>

<script>
  import XLSX from "xlsx";
  import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx/dist/vue-xlsx.es.js";
  import DoughnutChart from './dashboard/components/chartjs/DoughnutChart.vue';
  import calculator_mixin from "./mixins/calculator_mixin";
  import VueApexCharts from 'vue-apexcharts';
  import axios from "axios";
  import VueRecaptcha from 'vue-recaptcha';

  const optionsChartOOH = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      animateRotate: false,
    },
  }

  const optionsChartDOOH = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      animateRotate: false,
    },
  }

  export default {
    name: 'CarbonCalculator',
    components: {
      DoughnutChart,
      apexchart: VueApexCharts,
      XlsxWorkbook,
      XlsxSheet,
      XlsxDownload,
      VueRecaptcha
    },
    mixins: [calculator_mixin],
    data() {
      return {
        mois_maj_fe: "...",
        excel_style_ligne: [],
        excel_merge: [],
        user_information_entered: false,
        user_information_object: {},
        sitekey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
        valid_captcha: false,
        display_captcha_required_message: false,
        field_values: ["Agence", "Régie", "Annonceur", "Autre"],
        cookies_accepted: false,
        gdpr_accepted: false,
        user_information: {
          firstname: '',
          lastname: '',
          email: '',
          field: '',
          company: '',
          position: ''
        },
        requiredTextRules: [
          v => !!v || 'Information requise',
          v => (v && v.length < 35) || 'Maximum 35 caractères',
        ],
        nonRequiredTextRules: [
          v => {
            if (v) return v.length <= 50 || 'Maximum 35 caractères';
            else return true;
          }
        ],
        requiredCheckbox: [
          v => !!v || 'Information requise',
        ],
        emailRules: [
          v => !!v || 'Information requise',
          v => /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(v) || 'Format non valide',
        ],
        selectorRules: [(v) => !!v || "Information requise"],
        results_loading: false,
        chartOptions: {
          annotations: {
            points: [{
              x: 'Categorie',
              seriesIndex: 0,
              label: {
                borderColor: '#775DD0',
                offsetY: 0,
                style: {
                  color: '#fff',
                  background: '#775DD0',
                }
              }
            }]
          },
          chart: {
            height: 350,
            type: 'bar',
            width: '100%'
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              columnWidth: '50%',
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 1,
            colors:['#949290']
          },

          grid: {
            row: {
              colors: ['#fff', '#f2f2f2']
            },
            padding: {
                top: 0,
                right: 10,
                bottom: 0,
                left: 10
            }
          },
          xaxis: {
            labels: {
              rotate: -45
            },
            categories: ['Fabrication', 'Transport', 'Installation', 'Exploitation', 'Fin de vie'],
            tickPlacement: 'on'
          },
          yaxis: {
            title: {
              text: 'Consommation du poste (kg CO2)',
            },
          },
          fill: {
            colors:['#b02722'],
            type: 'gradient',
            gradient: {
              shade: 'light',
              type: "horizontal",
              shadeIntensity: 0.25,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 0.85,
              opacityTo: 0.85,
              stops: [50, 0, 100]
            },
          }
        },
        dialog_gdpr: false,
        dialog_how_to: false,
        dialog_approach: false,
        selectedCampaignTab: 1,
        selectedResultTab: 1,
        selectedFormatTypeTab: 1,
        ooh_acv: {},
        dooh_acv: {},
        optionsChartOOH,
        optionsChartDOOH,
        current_new_item_step: 0,
        chartDataOOH: {
          labels: ['Extraction/fabrication', 'Transport', 'Installation', 'Exploitation', 'Fin de vie'],
          datasets: [
            {
              backgroundColor: ['#a8e6cf', '#dcedc1', '#ffd3b6', '#ffaaa5', '#ff8b94'],
            },
          ],
        },
        chartDataDOOH: {
          labels: ['Extraction/fabrication', 'Transport', 'Installation', 'Exploitation', 'Fin de vie'],
          datasets: [
            {
              backgroundColor: ['#a8e6cf', '#dcedc1', '#ffd3b6', '#ffaaa5', '#ff8b94'],
            },
          ],
        },
        dialogOOHDOOH: false,
        dialogDeleteOOH: false,
        dialogDeleteDOOH: false,
        editedIndexOOH: -1,
        editedIndexDOOH: -1,
        editedItemOOH: {
          panel_num: '',
          panel_format: '',
          panel_duration: '',
        },
        defaultItemOOH: {
          panel_num: '',
          panel_format: '',
          panel_duration: '',
        },
        editedItemDOOH: {
          screen_format: '',
          screen_logs: '',
          screen_duration: '',
        },
        defaultItemDOOH: {
          screen_format: '',
          screen_logs: '',
          screen_duration: '',
        },
        panel_formats: ["0,5m2 non éclairé", "1m2 éclairé/non éclairé", "1,5m2 colle (rampes métro)", "2m2 fixe/déroulant pondéré", "3m2 colle", "4m2 colle", "6m2 colle", "8m2 fixe/déroulant pondéré", "12m2", "Avant bus", "Arrière classique bus", "Arrière bus autres formats", "Flanc droit et flanc gauche bus", "Colonne fixe"],
        screen_formats: ['Tous formats outdoor pondéré DOOH', 'Tous formats indoor pondéré DOOH'],
        ooh_table_headers: [
          {
            text: 'Format',
            value: 'panel_format',
            align: 'center',
            width: '30%',
          },
          {
            text: 'Nombre de faces',
            align: 'center',
            value: 'panel_num',
            width: '30%',
          },
          {
            text: 'Durée',
            value: 'panel_duration',
            align: 'center',
            bold: 'en jours',
            width: '30%',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            width: '10%',
          },
        ],
        ooh_items: [],
        dooh_table_headers: [
          {
            text: 'Format',
            align: 'center',
            value: 'screen_format',
            width: '30%',
          },
          {
            text: 'Nombre de logs',
            value: 'screen_logs',
            align: 'center',
            width: '30%',
            bold: '',
            tooltip: 'Un log correspond à l’affichage d’un spot sur un écran publicitaire numérique'
          },
          {
            text: 'Durée',
            value: 'screen_duration',
            align: 'center',
            bold: 'en secondes',
            width: '30%'
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            width: '10%',
          },
        ],
        dooh_items: [],
        ooh_emissions: [],
        dooh_emissions: [],
        categories_emissions_all: {
          'emissions': [],
          'ooh_emissions': [],
          'dooh_emissions': [],
          'formatted_emissions': [],
          'ooh_formatted_emissions': [],
          'dooh_formatted_emissions': []
        },
        fullPage: true,
        tabs_campaign: null,
        tabs_results: null,
        tabs_new_format_type: null,
        text: '',
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },
    computed: {
      total_emission_display_value() {
        if (this.categories_total_emissions > 0 && this.categories_total_emissions < 0.1) {
          return '<0,1 kg'
        }
        return (parseInt(10 * this.categories_total_emissions) / 10).toLocaleString() + ' kg'
      },
      fabrication_emission_display_value() {
        let category_emissions = this.categories_emissions[0];
        if (!category_emissions > 0) {
          return '0 kg';
        } else if (category_emissions > 0 && category_emissions < 0.1) {
          return '<0,1 kg'
        }
        return (parseInt(10 * category_emissions) / 10).toLocaleString() + ' kg'
      },
      transport_emission_display_value() {
        let category_emissions = this.categories_emissions[1];
        if (!category_emissions > 0) {
          return '0 kg';
        } else if (category_emissions > 0 && category_emissions < 0.1) {
          return '<0,1 kg'
        }
        return (parseInt(10 * category_emissions) / 10).toLocaleString() + ' kg'
      },
      installation_emission_display_value() {
        let category_emissions = this.categories_emissions[2];
        if (!category_emissions > 0) {
          return '0 kg';
        } else if (category_emissions > 0 && category_emissions < 0.1) {
          return '<0,1 kg'
        }
        return (parseInt(10 * category_emissions) / 10).toLocaleString() + ' kg'
      },
      exploitation_emission_display_value() {
        let category_emissions = this.categories_emissions[3];
        if (!category_emissions > 0) {
          return '0 kg';
        } else if (category_emissions > 0 && category_emissions < 0.1) {
          return '<0,1 kg'
        }
        return (parseInt(10 * category_emissions) / 10).toLocaleString() + ' kg'
      },
      fin_de_vie_emission_display_value() {
        let category_emissions = this.categories_emissions[4];
        if (!category_emissions > 0) {
          return '0 kg';
        } else if (category_emissions > 0 && category_emissions < 0.1) {
          return '<0,1 kg'
        }
        return (parseInt(10 * category_emissions) / 10).toLocaleString() + ' kg'
      },
      categories_emissions() {
        return this.categories_emissions_all.emissions;
      },
      ooh_categories_emissions() {
        return this.categories_emissions_all.ooh_emissions;
      },
      dooh_categories_emissions() {
        return this.categories_emissions_all.dooh_emissions;
      },
      formatted_categories_emissions() {
        return this.categories_emissions_all.formatted_emissions;
      },
      ooh_formatted_categories_emissions() {
        return this.categories_emissions_all.ooh_formatted_emissions;
      },
      dooh_formatted_categories_emissions() {
        return this.categories_emissions_all.dooh_formatted_emissions;
      },
      categories_total_emissions() {
        return this.categories_emissions.reduce((partial_sum, a) => partial_sum + a, 0);
      },
      series() {
        return [{
          name: 'Consommation totale (kgCO2)',
          data: this.formatted_categories_emissions
        }, {
          name: 'Consommation OOH (kgCO2)',
          data: this.ooh_formatted_categories_emissions
        }, {
          name: 'Consommation DOOH (kgCO2)',
          data: this.dooh_formatted_categories_emissions
        }]
      },
      formTitleOOH () {
        return this.editedIndexOOH === -1 ? 'Ajouter un format de panneau' : 'Modifier un format de panneau'
      },
      formTitleDOOH () {
        return this.editedIndexDOOH === -1 ? 'Ajouter un format de panneau' : 'Modifier un format de panneau'
      },
      currentlyEditingFormat() {
        return this.editedIndexOOH > -1 || this.editedIndexDOOH > -1;
      },
      editedItemOOHComplete () {
        return (this.editedItemOOH.panel_num > 0 && this.editedItemOOH.panel_format.length > 0 && this.editedItemOOH.panel_duration > 0)
      },
      editedItemDOOHComplete () {
        return (this.editedItemDOOH.screen_format.length > 0 && this.editedItemDOOH.screen_duration > 0 && this.editedItemDOOH.screen_logs > 0)
      },
      userInformationComplete () {
        return (this.user_information.firstname.length > 0 && this.user_information.firstname.length > 0 && this.editedItemDOOH.screen_logs > 0)
      },
      ooh_data_excel_format() {
        let ooh_data_excel_format = [];
        let ooh_items = this.ooh_items;
        ooh_items.forEach((ooh_item) => {
          ooh_data_excel_format.push([null,"'" + ooh_item.panel_format + "'", ooh_item.panel_num, ooh_item.panel_duration]);
        });
        return ooh_data_excel_format;
      },
      dooh_data_excel_format() {
        let dooh_data_excel_format = [];
        let dooh_items = this.dooh_items;
        dooh_items.forEach((dooh_item) => {
          dooh_data_excel_format.push([null,"'" + dooh_item.screen_format + "'", dooh_item.screen_logs, dooh_item.screen_duration]);
        });
        return dooh_data_excel_format;
      },
      emissions_data_excel_format() {
        let emissions_data_excel_format = [];
        let categories_emissions = this.categories_emissions;
        let total_emissions = categories_emissions.reduce((partial_sum, a) => partial_sum + a, 0);
        emissions_data_excel_format.push(null, this.formatNumber(categories_emissions[0]), this.formatNumber(categories_emissions[1]), this.formatNumber(categories_emissions[2]), this.formatNumber(categories_emissions[3]), this.formatNumber(categories_emissions[4]),null,this.formatNumber(total_emissions));
        return emissions_data_excel_format;
      },
      ooh_emissions_data_excel_format() {
        let ooh_emissions_data_excel_format = [];
        let ooh_categories_emissions = this.ooh_categories_emissions;
        let ooh_total_emissions = ooh_categories_emissions.reduce((partial_sum, a) => partial_sum + a, 0);
        ooh_emissions_data_excel_format.push(null, this.formatNumber(ooh_categories_emissions[0]), this.formatNumber(ooh_categories_emissions[1]), this.formatNumber(ooh_categories_emissions[2]), this.formatNumber(ooh_categories_emissions[3]), this.formatNumber(ooh_categories_emissions[4]),null,this.formatNumber(ooh_total_emissions));
        return ooh_emissions_data_excel_format;
      },
      dooh_emissions_data_excel_format() {
        let dooh_emissions_data_excel_format = [];
        let dooh_categories_emissions = this.dooh_categories_emissions;
        let dooh_total_emissions = dooh_categories_emissions.reduce((partial_sum, a) => partial_sum + a, 0);
        dooh_emissions_data_excel_format.push(null, this.formatNumber(dooh_categories_emissions[0]), this.formatNumber(dooh_categories_emissions[1]), this.formatNumber(dooh_categories_emissions[2]), this.formatNumber(dooh_categories_emissions[3]), this.formatNumber(dooh_categories_emissions[4]),null,this.formatNumber(dooh_total_emissions));
        return dooh_emissions_data_excel_format;
      },
      sheets() {

        this.excel_style_ligne = [];
        this.excel_merge = [];

        let titre = {
          font: {
            sz: 16,
            bold: true,
            color: {
              rgb: "FFFFFF"
            }
          },
          alignment: {
            horizontal: "center"
          },
          fill: {
            patternType: "solid",
            fgColor: { rgb: "b02722" },
            bgColor: { rgb: "b02722" }
          }
        };

        let lien = {
          font: {
            color: {
              rgb: "b02722"
            }
          },
          alignment: {
            horizontal: "center"
          },
          fill: {
            patternType: "solid",
            fgColor: { rgb: "CCCCCC" },
            bgColor: { rgb: "CCCCCC" }
          }
        };

        let section = {
          font: {
            bold: true,
            color: {
              rgb: "b02722"
            }
          },
          alignment: {
            horizontal: "center"
          },
          fill: {
            patternType: "solid",
            fgColor: { rgb: "CCCCCC" },
            bgColor: { rgb: "CCCCCC" }
          }
        };

        let nom_tableau = {
          font: {
            color: {
              rgb: "FFFFFF"
            }
          },
          alignment: {
            horizontal: "center"
          },
          fill: {
            patternType: "solid",
            fgColor: { rgb: "b02722" },
            bgColor: { rgb: "b02722" }
          },
          border: {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          }
        };

        let colone = {
          font: {
            color: {
              rgb: "b02722"
            }
          },
          fill: {
            patternType: "solid",
            fgColor: { rgb: "CCCCCC" },
            bgColor: { rgb: "CCCCCC" }
          },
          alignment: {
            horizontal: "center"
          },
          border: {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          }
        };

        let data = {

          border: {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          },
          alignment: {
            horizontal: "center"
          }
        }


        this.excel_style_ligne.push(null); this.excel_merge.push(0);
        this.excel_style_ligne.push(titre); this.excel_merge.push(7);
        this.excel_style_ligne.push(lien); this.excel_merge.push(7);
        this.excel_style_ligne.push(null); this.excel_merge.push(0);
        this.excel_style_ligne.push(section); this.excel_merge.push(7);
        this.excel_style_ligne.push(null); this.excel_merge.push(0);
        this.excel_style_ligne.push(nom_tableau); this.excel_merge.push(3);
        this.excel_style_ligne.push(colone); this.excel_merge.push(0);
        let excel_data = [
          [null,"Sur la base des facteurs d'émissions mis à jour en "+this.mois_maj_fe],
          [null,"Calculateur carbone UPE"],
          [null,"Extrait du site : http://carbone-calculateur-adoohcc.upe.fr/"],
          [],
          [null,"Rappel des données d'entrée de la campagne"],
          [],
          [null,"Campagnes Print (OOH)","",""],
          [null,"Format", "Nombre de faces", "Durée en jours"]
        ];

        this.ooh_data_excel_format.forEach((ooh_data) => {
          excel_data.push(ooh_data);
          this.excel_style_ligne.push(data); this.excel_merge.push(0);
        });


        this.excel_style_ligne.push(null); this.excel_merge.push(0);
        this.excel_style_ligne.push(nom_tableau); this.excel_merge.push(3);
        this.excel_style_ligne.push(colone); this.excel_merge.push(0);
        excel_data.push(
          [],
          [null,"Campagnes Digitales (DOOH)","",""],
          [null,"Format", "Nombre de logs", "Durée du spot en secondes"]
        );

        this.dooh_data_excel_format.forEach((dooh_data) => {
          excel_data.push(dooh_data)
          this.excel_style_ligne.push(data); this.excel_merge.push(0);
        });


        this.excel_style_ligne.push(null); this.excel_merge.push(0);
        this.excel_style_ligne.push(section); this.excel_merge.push(7);
        this.excel_style_ligne.push(null); this.excel_merge.push(0);
        this.excel_style_ligne.push(nom_tableau); this.excel_merge.push(7);
        this.excel_style_ligne.push(colone); this.excel_merge.push(0);
        this.excel_style_ligne.push(data); this.excel_merge.push(0);
        excel_data.push(
          [],
          [null,"Résultat d'émissions"],
          [],
          [null,"Émissions des campagnes (global: OOH & DOOH)","","","","",""],
          [null, "Matières premières et fabrication (kgCO2)", "Transport (kgCO2)", "Installation (kgCO2)", "Exploitation / Diffusion (kgCO2)", "Fin de vie (kgCO2)",null,"Total (kgCO2)"],
          this.emissions_data_excel_format
        );


        this.excel_style_ligne.push(null); this.excel_merge.push(0);
        this.excel_style_ligne.push(nom_tableau); this.excel_merge.push(7);
        this.excel_style_ligne.push(colone); this.excel_merge.push(0);
        this.excel_style_ligne.push(data); this.excel_merge.push(0);
        excel_data.push(
          [],
          [null,"Détail des émissions : OOH seulement","","","","",""],
          [null, "Matières premières et fabrication (kgCO2)", "Transport (kgCO2)", "Installation (kgCO2)", "Exploitation / Diffusion (kgCO2)", "Fin de vie (kgCO2)",null,"Total (kgCO2)"],
          this.ooh_emissions_data_excel_format
        );

        this.excel_style_ligne.push(null); this.excel_merge.push(0);
        this.excel_style_ligne.push(nom_tableau); this.excel_merge.push(7);
        this.excel_style_ligne.push(colone); this.excel_merge.push(0);
        this.excel_style_ligne.push(data); this.excel_merge.push(0);
        excel_data.push(
          [],
          [null,"Détail des émissions : DOOH seulement","","","","",""],
          [null, "Matières premières et fabrication (kgCO2)", "Transport (kgCO2)", "Installation (kgCO2)", "Exploitation / Diffusion (kgCO2)", "Fin de vie (kgCO2)",null,"Total (kgCO2)"],
          this.dooh_emissions_data_excel_format
        );
        return [{ name: "SheetOne", data: excel_data}]
      }
    },
    mounted() {
      this.checkUserInformation();

      this.get_mois_maj_acv().then((res) => {
        this.mois_maj_fe = res;
      })

    },
    methods: {

      formatNumber( num )
      {
        if (num == undefined)
          return '';

        try {
          return ( '' + num.toFixed(3) );
        }
        catch(error)
        {
          return ( '' + num );
        }
      },

      testWorksheet()
      {
        const myXLSX = require('sheetjs-style');
        const wb = myXLSX.utils.book_new();

        let worksheet = myXLSX.utils.aoa_to_sheet(this.sheets[0].data);


        let merge = [];
        let line = 0;
        this.excel_merge.forEach( col => {
          if (col > 1)
          {
            merge.push({ s: { r: line, c: 1 }, e: { r: line, c: col } })
          }
          line ++;
        });
        worksheet["!merges"] = merge;

        for (const [key, value] of Object.entries(worksheet)) {
          try {
            let ligne = parseInt( key.substring(1) , 10 ) -1 ;
            worksheet[key].s = this.excel_style_ligne[ligne];

            if (  isNaN(parseFloat(worksheet[key].v)) )
            {
              worksheet[key].t = 's';
            }
            else
            {
              worksheet[key].t = 'n';
            }
          }
          catch (error)
          {
             console.log(error);
          }
        }

        console.log(worksheet);

        worksheet['!cols']=[
          { wch: 3 },
          { wch: 35 },
          { wch: 23 },
          { wch: 23 },
          { wch: 27 },
          { wch: 17 },
          { wch: 3 },
          { wch: 23 },

        ];


        myXLSX.utils.book_append_sheet(wb, worksheet, 'export')
        myXLSX.writeFile(wb, 'Emissions carbones de mes campagnes publicitaires - Analyse du ' + this.getTimestamp() + '.xlsx');
      },

      validateEmail() {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
              this.msg['email'] = 'Please enter a valid email address';
          } else {
              this.msg['email'] = '';
          }
      },
      applyValidCaptcha() {
        this.valid_captcha = true;
        this.display_captcha_required_message = false;
      },
      checkUserInformation() {
        let user_information = JSON.parse(localStorage.getItem('user_information'));
        if (user_information && user_information.firstname && user_information.lastname && user_information.email && user_information.field) {
          this.user_information_entered = true;
          this.user_information_object = user_information;
        }
      },
      submitUserInformationForm() {
        let valid_captcha = this.valid_captcha;
        if (!valid_captcha) {
          this.display_captcha_required_message = true;
        }
        let valid_form = this.$refs.user_information_form.validate();
        if (valid_captcha && valid_form) {
          let user_information = this.user_information;
          this.user_information_object = user_information;
          this.sendUserInformation();
          localStorage.setItem('user_information', JSON.stringify(user_information));
          this.user_information_entered = true;
          let aze = localStorage.getItem('user_information');
        }
      },
      updateCategoriesEmissionsAll() {
        const ooh_dooh_emissions = {};
        let ooh_emissions = this.ooh_emissions;
        let dooh_emissions = this.dooh_emissions;
        for (let [key, value] of Object.entries(ooh_emissions)) {
          if (ooh_dooh_emissions[key]) {
            ooh_dooh_emissions[key] += value;
          } else {
            ooh_dooh_emissions[key] = value;
          }
        }
        for (let [key, value] of Object.entries(dooh_emissions)) {
          if (ooh_dooh_emissions[key]) {
            ooh_dooh_emissions[key] += value;
          } else {
            ooh_dooh_emissions[key] = value;
          }
        }

        let self = this;
        let chart_categories = this.chartOptions.xaxis.categories;
        let categories_emissions = [];
        let ooh_categories_emissions = [];
        let dooh_categories_emissions = [];
        let formatted_categories_emissions = [];
        let ooh_formatted_categories_emissions = [];
        let dooh_formatted_categories_emissions = [];
        chart_categories.forEach((category) => {
          let category_name = category;
          if (category == "Fabrication") {
            category_name = "Matières premières & fabrication";
          }
          if (category == "Transport") {
            category_name = "Transport amont";
          }
          let category_emissions = ooh_dooh_emissions[category_name] || 0;
          categories_emissions.push(category_emissions);
          let ooh_category_emissions = ooh_emissions[category_name] || 0;
          ooh_categories_emissions.push(ooh_category_emissions);
          let dooh_category_emissions = dooh_emissions[category_name] || 0;
          dooh_categories_emissions.push(dooh_category_emissions);

          if (Math.abs(category_emissions) >= 0.1) {
            formatted_categories_emissions.push(parseInt(10 * category_emissions) / 10);
          } else {
            formatted_categories_emissions.push(0);
          }
          if (Math.abs(ooh_category_emissions) >= 0.1) {
            ooh_formatted_categories_emissions.push(parseInt(10 * ooh_category_emissions) / 10);
          } else {
            ooh_formatted_categories_emissions.push(0);
          }
          if (Math.abs(dooh_category_emissions) >= 0.1) {
            dooh_formatted_categories_emissions.push(parseInt(10 * dooh_category_emissions) / 10);
          } else {
            dooh_formatted_categories_emissions.push(0);
          }
        });
        self.categories_emissions_all = {
          'emissions': categories_emissions,
          'ooh_emissions': ooh_categories_emissions,
          'dooh_emissions': dooh_categories_emissions,
          'formatted_emissions': formatted_categories_emissions,
          'ooh_formatted_emissions': ooh_formatted_categories_emissions,
          'dooh_formatted_emissions': dooh_formatted_categories_emissions
        }
        return;
      },
      getTimestamp() {
        const today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const timestamp = date + ' ' + time;
        return timestamp;
      },
      updateSelectedCampaignTab (e) {
        this.selectedCampaignTab = e.charAt(e.length - 1)
      },
      updateSelectedResultTab (e) {
        this.selectedResultTab = e.charAt(e.length - 1)
      },
      updateSelectedFormatTypeTab (e) {
        this.selectedFormatTypeTab = e.charAt(e.length - 1)
      },
      calculateStats () {
        this.isLoading = true
        // simulate Calculation
        setTimeout(() => {
          this.isLoading = false
        }, 5000)
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      editItemOOH (item) {
        this.tabs_new_format_type = 'ooh';
        this.editedIndexOOH = this.ooh_items.indexOf(item);
        this.current_new_item_step =
        this.editedItemOOH = Object.assign({}, item)
        this.dialogOOHDOOH = true
      },
      deleteItemOOH (item) {
        this.editedIndexOOH = this.ooh_items.indexOf(item)
        this.editedItemOOH = Object.assign({}, item)
        this.dialogDeleteOOH = true
      },
      deleteItemConfirmOOH () {
        this.ooh_items.splice(this.editedIndexOOH, 1)
        this.closeDeleteOOH()
      },
      closeOOHDOOH () {
        this.dialogOOHDOOH = false
        this.$nextTick(() => {
          this.editedItemOOH = Object.assign({}, this.defaultItemOOH)
          this.editedIndexOOH = -1
        })
        this.$nextTick(() => {
          this.editedItemDOOH = Object.assign({}, this.defaultItemDOOH)
          this.editedIndexDOOH = -1
        })
      },
      closeDeleteOOH () {
        this.dialogDeleteOOH = false
        this.$nextTick(() => {
          this.editedItemOOH = Object.assign({}, this.defaultItemOOH)
          this.editedIndexOOH = -1
        })
      },
      saveOOH () {
        if (this.editedIndexOOH > -1) {
          Object.assign(this.ooh_items[this.editedIndexOOH], this.editedItemOOH)
        } else {
          this.ooh_items.push(this.editedItemOOH)
        }
        this.closeOOHDOOH()
        this.calculateCO2Emissions();
      },
      editItemDOOH (item) {
        this.tabs_new_format_type = 'dooh';
        this.editedIndexDOOH = this.dooh_items.indexOf(item)
        this.editedItemDOOH = Object.assign({}, item)
        this.dialogOOHDOOH = true
      },
      deleteItemDOOH (item) {
        this.editedIndexDOOH = this.dooh_items.indexOf(item)
        this.editedItemDOOH = Object.assign({}, item)
        this.dialogDeleteDOOH = true
      },
      deleteItemConfirmDOOH () {
        this.dooh_items.splice(this.editedIndexDOOH, 1)
        this.closeDeleteDOOH()
      },
      closeDeleteDOOH () {
        this.dialogDeleteDOOH = false
        this.$nextTick(() => {
          this.editedItemDOOH = Object.assign({}, this.defaultItemDOOH)
          this.editedIndexDOOH = -1
        })
      },
      saveDOOH () {
        if (this.currentlyEditingFormat) {
          Object.assign(this.dooh_items[this.editedIndexDOOH], this.editedItemDOOH)
        } else {
          this.dooh_items.push(this.editedItemDOOH)
        }
        this.closeOOHDOOH();
        this.calculateCO2Emissions();
      },
      printScreen: function () {
    	 window.print("#sticky-top-header");
     },
     resetData() {
       this.ooh_items = [];
       this.dooh_items = [];
     },
     async sendUserInformation() {
       let ooh_campaigns = [];
       let dooh_campaigns = [];
       let user_information_object = this.user_information_object;
       let request_data = {
         ooh_campaigns: ooh_campaigns,
         dooh_campaigns: dooh_campaigns,
         user_information: user_information_object
       }
       let self = this;
       self.results_loading = true;
       await axios
         .post('https://europe-west1-upe-calculette-carbone.cloudfunctions.net/calculateur-carbone-15-12-2021/', request_data)
         .then(response => {
         }).catch((err) => {
           console.log("Error")
         })
     },
     async calculateCO2Emissions() {
       let ooh_campaigns = this.ooh_items;
       let dooh_campaigns = this.dooh_items;
       let user_information_object = this.user_information_object;
       let request_data = {
         ooh_campaigns: ooh_campaigns,
         dooh_campaigns: dooh_campaigns,
         user_information: user_information_object
       }
       let self = this;
       self.results_loading = true;
       await axios
         .post('https://europe-west1-upe-calculette-carbone.cloudfunctions.net/calculateur-carbone-15-12-2021/', request_data)
         .then(response => {
           self.results_loading = false;
           let ooh_dooh_co2_emissions = response.data;
           self.ooh_emissions = ooh_dooh_co2_emissions.ooh_co2_emissions;
           self.dooh_emissions = ooh_dooh_co2_emissions.dooh_co2_emissions;
           self.updateCategoriesEmissionsAll();
           // Object.keys(self.ooh_acv).forEach(k => k = k.trim());
         }).catch(() => {
           self.results_loading = false;
         })
     }
    },
    watch: {
      dialogOOHDOOH (val) {
        val || this.closeOOHDOOH()
      },
      dialogDeleteOOH (val) {
        val || this.closeDeleteOOH()
      },
      dialogDeleteDOOH (val) {
        val || this.closeDeleteDOOH()
      },
      ooh_items () {
        this.calculateCO2Emissions();
      },
      dooh_items () {
        this.calculateCO2Emissions();
      },
    },
  }
</script>
<style lang="scss">
#app .success {
  background-color: #b02722 !important;
}
#user-information-card {
  & > div * {
    width: 100%;
    color: #FFFFFF !important;
  }
  .v-messages__message {
    color: red !important;
    font-style: italic;
  }
  #submit-user-information-button span {
    color: #FFFFFF !important;
    font-weight: 500;
  }
  .form-item-incomplete {
    margin: auto;
    width: max-content;
    i {
      color: red !important;
    }
  }
  #recaptcha-section {
    > div > div {
      margin: auto;
    }
  }
}
.grecaptcha-badge {
  position: inherit !important;
}
#adhooc-logo {
  padding: 3px 4px;
  border: 3px solid #FFFFFF;
}
#dashboard-content {
  display: table;
  width: 100%;
}
#results-section, #inputs-section {
  display: table-cell;
  vertical-align: top;
}
#inputs-top-section, #section-separator {
  width: 100%;
}
@media screen and (max-width: 991px) {
  #dashboard-content {
    display: block;
  }
  #results-section, #inputs-section {
    display: contents;
  }
  #inputs-section {
    max-width: calc(50% - 12px);
  }
  #inputs-top-section {
    width: 100% !important;
  }
  #data-tables table {
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 767px) {
  #page-title {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  #add-new-format-button {
    margin-left: -24px !important;
  }
}
.theme--light.v-data-table, .theme--light.v-data-table * {
  border: none;
  background: none;
  box-shadow: 0 0 0 0 white !important;
}
.exportCSV, .apexcharts-toolbar {
  display: none !important;
}
.apexcharts-tooltip-marker {
  display: none;
  // background-color: #b02722 !important;
}
.v-stepper__wrapper {
  height: auto !important;
}
table th, table td {
  border-right: 1px solid #0000003e !important;
  border-top: 1px solid #0000003e !important;
}
table th:first-of-type, table td:first-of-type {
  border-left: 1px solid #0000003e !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th > span {
  font-size: 1.5em;
}
.v-data-table__empty-wrapper tr, .v-data-table__empty-wrapper td {
  padding: 0;
}
#methodology-card, #approach-card {
  padding-bottom: 40px;
  ul {
    margin: 15px;
  }
  h3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  br {
    padding-bottom: 15px;
  }
}
#section-separator {
  padding-top: 0;
  height: 0;
  border-bottom: 1px solid #bd4b48;
}
#results-section .theme--light.v-tabs-items, #results-section .theme--light.v-application {
  background: none;
}
#action-buttons button{
  height: fit-content;
  span {
    margin: 5px 0;
  }
}
#reset-button {
  background-color: #949290;
  border-color: #949290;
}
.v-toolbar__content > .v-tabs:first-child, .v-toolbar__extension > .v-tabs:first-child {
  margin-left: -31px;
}
#results-details {
  background-color: #DDDDDD;
}
#summary-results .v-card--material__heading {
  background-color: #b02722 !important;
  border-color: #b02722 !important;
}
#tabs-results {
  width: 100%;
  max-width: 100%;
  padding: 0;
}
#results-values-section .v-card--material__heading {
  background-color: #b14a45 !important;
  border-color: #b14a45 !important;
}
#panel-OOH, #panel-DOOH, #panel-OOH *, #panel-DOOH * {
  border: none;
}
.v-sheet *, .v-application .v-tabs a {
  color: #333333 !important;
  caret-color: #333333 !important;
}
.v-alert {
  margin: 0;
}
#tabs-results > header {
  background: #dddddd;
}
#tabs-results a {
  background: #bfbfbf31;
  border-bottom: none;
}
#tabs-results a span {
  color: #707070 !important;
}
#tabs-results a.v-tab--active span {
  color: #000000 !important;
}
#tabs-results .v-stepper__step--complete span {
  color: #000000 !important;
}
#tabs-results a.v-tab--active {
  background: #a5a4a4;
  border-right: none;
}
#carbon-calculator {
  min-height: 90%;
  background: #ffffff;
}
#carbon-calculator .row {
  margin-left: 0;
}

#download-button {
  width: 100%;
}
#sticky-top-header {
  background: #b02722;
  color: #FFFFFF;
  position: sticky;
  display: block;
  top: 0;
  z-index: 9;
  max-height: 67px !important;
  height: 67px !important;
}
#top-header-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#page-title {
  font-size: 20px;
}
#results-section {
  background-color: #e5e5e5;
  max-height: fit-content;
  height: fit-content;
  padding: 0;
  min-height: calc(100vh - 67px);
  border: 1px solid #ABABAB;
}
#results-section.row {
  margin-left: 12px;
}
#new_item_card {
  padding: 0;
}
#new_item_card .v-stepper__content {
  padding-top: 0;
  padding-bottom: 0;
}
.results-title {
  text-align: center;
  width: 100%;
}
#add-new-format-button {
  border: 1px solid #f5e3e3;
  border-color: #f5e3e3 !important;
}
#add-new-format-button-text {
  font-weight: 1000;
}
#card-results-header {
  margin: auto;
}
.v-card--material .body-3{
  color: #333333 !important;
  font-weight: 400 !important;
}
.v-card--material .display-2{
  font-weight: 400 !important;
}
#cards-results {
  margin: 0 !important;
}
#cards-results .v-sheet.v-card {
  margin: 0;
}
#cards-results span.v-stepper__step__step.primary {
  background-color: #b02722 !important;
}
#cards-results span.v-stepper__step__step, #cards-results span.v-stepper__step__step .v-icon {
  color: #ffffff !important;
}
#results-values-section {
  max-width: 100%;
}
#graph-results svg path {
  // fill: #b02722;

}
#graph-results .apexcharts-bar-series.apexcharts-plot-series {
  g:nth-child(1) path {
    fill: #b02722 !important;
    margin-left: 3px;
  }
  g:nth-child(2) path {
    fill: #f5e3e3 !important;
  }
  g:nth-child(3) path {
    fill: #db9e9c !important;
  }
}
#graph-results .apexcharts-legend.apx-legend-position-bottom {
  div:nth-child(1) {
    .apexcharts-legend-marker {
      background: #b02722 !important;
      display: none;
    }
    .apexcharts-legend-text {
      background: #b02722 !important;
      color: #FFF !important;
      padding: 6px;
      border-radius: 6px;
      margin: 4px;
    }
  }
  div:nth-child(2) {
    .apexcharts-legend-marker {
      background: #f5e3e3 !important;
      display: none;
    }
    .apexcharts-legend-text {
      background: #f5e3e3 !important;
      color: #000 !important;
      padding: 6px;
      border-radius: 6px;
      margin: 4px;
    }
  }
  div:nth-child(3) {
    .apexcharts-legend-marker {
      background: #db9e9c !important;
      display: none;
    }
    .apexcharts-legend-text {
      background: #db9e9c !important;
      color: #000 !important;
      padding: 6px;
      border-radius: 6px;
      margin: 10px;
    }
  }
}
.data-tables-title {
  width: fit-content;
  margin: auto;
  font-weight: 300;
  border-bottom: 1px solid black;
}
.v-card .v-card--material__heading {
  top: -20px;
}
.v-card {
  margin-top: 10px;
  margin-bottom: 5px;
}
#calculator-image {
  display: block;
  margin: auto;
}
#inputs-top-section {
  background: #f5e3e3;
}
#inputs-top-section.row {
  margin-left: 0px;
}
#inputs-section {
  margin-top: -12px;
  padding-left: 0;
  padding-right: 0;
  background-color: #FFFFFF;
  overflow:scroll;
  height: calc(100vh - 67px);
}
.v-image {
  margin-left: -16px;
  margin-top: -16px;
  min-width: 32px;
}
.v-image__image .v-image__image--cover {
  background-size: 25px;
}
.information-card h3 {
  font-weight: 500;
  font-size: 1.4em;
}
.information-card .body-3 {
  color: black;
  font-weight: 500;
  font-size: 1.2em;
}
.v-main__wrap.v-content__wrap {
}
.v-main.v-content {
  height: 100vh;
}
.v-card--material {
  box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 5px !important;
}
.v-card--material.v-card--material-stats.v-card--material--has-heading > div:last-of-type{
  display: none;
}
#dialogs-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#more-information-button, #approach-button {
  cursor: pointer;
  * {
    color: #b02722;
    font-weight: 600;
  }
}
.theme--light.v-data-table {
}
.v-divider {
  padding: 0px;
}
#panel-OOH table, #panel-DOOH table{
  max-width: 97%;
  margin: 15px;
  box-shadow: rgba(0, 0, 0, 0.5) 4px 4px 5px !important;
}
#panel-OOH .v-data-footer, #panel-DOOH .v-data-footer {
  display: none;
}
.m-auto {
  margin: auto !important;
}
.space-around {
  justify-content: space-evenly;
}// 042A2B // DCEOD9

.logo-upe {
  display: block;
  margin: auto;
  margin-top : 15px ;
}

.titre-page {
  margin-top: 15px;
}
.adoohcc-legende {
  font-size : 12px ;
  display : block ;
}
.adoohcc-div {
  margin-top: 8px;
  margin-right: 5px;
}
#adoohcc-logo {
  padding: 3px 4px;
  border: 3px solid #FFFFFF;
}
</style>
